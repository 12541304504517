import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import PageHead from "@Components/PageHead";
import CallToAction from "@Components/CallToAction";

function FrontEnd() {
  const data = useStaticQuery(PageQuery);
  return (
    <>
      <PageHead backgroundImg={data.frontendHero.childImageSharp.fluid}>
        <h2 className="head-title">Frontend and Desktop</h2>
      </PageHead>
      <section className="container service__frontend">
        <div>
          <h1>React</h1>
        </div>
      </section>
      <CallToAction />
    </>
  );
}

export default FrontEnd;

export const PageQuery = graphql`
  query {
    frontendHero: file(relativePath: { eq: "designHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
